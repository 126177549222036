
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { formatDateTimePHP } from "@/core/helpers/date";

export default defineComponent({
  name: "create-event",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.EVENT + "getIsLoading"];
    });

    let eventCategories = computed(() => {
      return store.getters[Modules.MASTER + "getEventCategories"];
    });

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const imageURL = ref();

    const schema = yup.object({
      type: yup.string().required().label("Event Type"),
      visibility: yup.string().required().label("Event Visibility"),
      name: yup.string().required().max(255).label("Event Name"),
      description: yup.string().required().label("Description"),
      event_category_id: yup.string().required().label("Event Category"),
      environment_id: yup.string().required().label("Environment ID"),
      max_people_limit: yup.string().required().label("Max People Limit"),
      start_at: yup.string().required().label("Start At"),
      end_at: yup.string().required().label("End At"),
      image: yup.string().required().label("Image"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        type: "",
        visibility: "",
        name: "",
        description: "",
        event_category_id: "",
        environment_id: "",
        max_people_limit: "",
        start_at: "",
        end_at: "",
        image: "",
      },
      validationSchema: schema,
    });

    const { value: type } = useField("type");
    const { value: visibility } = useField("visibility");
    const { value: name } = useField("name");
    const { value: description } = useField("description");
    const { value: event_category_id } = useField("event_category_id");
    const { value: environment_id } = useField("environment_id");
    const { value: max_people_limit } = useField("max_people_limit");
    const { value: start_at } = useField("start_at");
    const { value: end_at } = useField("end_at");
    const { value: image } = useField("image");

    watch(type, (value) => {
      if (value == "instant") {
        visibility.value = "private";
        name.value = "Instant Event";
        description.value = "Instant Event";
        start_at.value = "";
        end_at.value = "";
      } else {
        visibility.value = "";
        name.value = "";
        description.value = "";
        start_at.value = "";
        end_at.value = "";
      }
    });

    const onSubmit = handleSubmit((values) => {
      // values.start_at = formatDateTimePHP(values.start_at);
      // values.end_at = formatDateTimePHP(values.end_at);
      // values.image = imageURL.value;

      let formData = new FormData();
      formData.append("type", values.type!);
      formData.append("visibility", values.visibility!);
      formData.append("name", values.name!);
      formData.append("description", values.description!);
      formData.append("event_category_id", values.event_category_id!);
      formData.append("environment_id", values.environment_id!);
      formData.append("max_people_limit", values.max_people_limit!);
      formData.append("start_at", formatDateTimePHP(values.start_at));
      formData.append("end_at", formatDateTimePHP(values.end_at));
      formData.append("image", values.image!, "hello.jpg");

      return new Promise<void>((resolve) => {
        store
          .dispatch(Modules.EVENT + Actions.STORE_EVENT, formData)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.EVENT + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "myEvents" });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.EVENT + "getErrors"]);
            Swal.fire({
              text: "Failed to create an event. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    function chooseImage(event) {
      if (event.target.files.length === 0) {
        image.value = "";
        imageURL.value = "";
        return;
      }
      image.value = event.target.files[0];
    }

    watch(image, (image) => {
      if (!(image instanceof File)) {
        return;
      }
      let fileReader = new FileReader();

      fileReader.readAsDataURL(image);
      fileReader.onload = (event: Event) => {
        imageURL.value = fileReader.result;
      };
    });

    type.value = "scheduled";

    onMounted(() => {
      setCurrentPageTitle("Create Event");
    });

    return {
      isLoading,
      eventCategories,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,

      type,
      visibility,
      name,
      description,
      event_category_id,
      environment_id,
      max_people_limit,
      start_at,
      end_at,
      image,

      imageURL,
      chooseImage,

      formatDateTimePHP,
    };
  },
});
